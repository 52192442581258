/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

import amortizedPayment from '../models/AmortizedPayment';

/**
 * Amort function:
 * Calculates the necessary elements of the loan using the supplied user input
 * and then displays each months updated amortization schedule on the page
 */
export const amortize = (balance, interestRate, months) => {
  //Calculate the per month interest rate
  const monthCount = 12;
  const p100 = 100;
  const monthlyRate = interestRate / p100 / monthCount;

  const paymentSchedule = [];

  let totalPrincipal = 0;
  let totalInterest = 0;

  const payment = balance * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -months)));

  for (let paymentNumber = 0; paymentNumber < months; paymentNumber++) {
    let interest = 0;
    let principal = 0;

    interest = balance * monthlyRate;
    principal = payment - interest;
    balance -= principal;

    totalPrincipal += principal;
    totalInterest += interest;
    paymentSchedule.push(new amortizedPayment(paymentNumber + 1, principal, interest, totalPrincipal, totalInterest));
  }

  return paymentSchedule;
};
