/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */

export default class AmortizedPayment {
    constructor (paymentNumber, principal, interest, totalPrincipal, totalInterest) {
        this.paymentNumber = paymentNumber;
        this.principal = principal;
        this.interest = interest;
        this.totalPrincipal = totalPrincipal;
        this.totalInterest = totalInterest;
    }
}
